import React, {useEffect, useState} from 'react'

import _ from 'lodash'
import {Link, useParams, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'

import useMediaQuery from "@mui/material/useMediaQuery";
import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import {Button} from '@mui/material'
import TextField from '@mui/material/TextField'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import Alert from '@mui/material/Alert'
import Switch from '@mui/material/Switch'
import Grid from '@mui/material/Unstable_Grid2'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

import PanelHead from '../../components/panel-head'
import {getAdminDetail, newAdmin, editAdmin, deleteAdmin, getEticketEvents} from '../../lib/request'
import {setLoading, setSnackbarMsg} from '../../store/reducers/misc'
import DeleteConfirmationDialog from './delete-confirmation-dialog'

import acl from '../../lib/acl'
import MenuItem from "@mui/material/MenuItem";

const StyledDiv = styled(Box)`
  ${({theme}) => `
    
  `}
`

export default function CreateEditForm(props) {

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))
	const {id} = useParams()

	const dispatch = useDispatch()
	const brands = useSelector((state) => state.brand.brands)
	const navigate = useNavigate()
	const {me} = useSelector(state => state.profile)

	const [form, setForm] = useState({
		id: null,
		username: '',
		usernameErr: null,
		password: '',
		passwordErr: null,
		password_confirmation: '',
		password_confirmationErr: null,
		active: false,
		verify: false,
		brandIds: [],
		brandIdsErr: null,
		module: [],
		moduleErr: null,
		permission: ['Read'],
		eventAccess: null
	})
	const [onDelete, setOnDelete] = useState(false)
	const [module] = useState(acl.flat)
	const [permission] = useState(acl.permissions)
	const [events, setEvents] = useState([])

	useEffect(() => {
		getEticketEvents({brandId: process.env.REACT_APP_ETICKET_BRANDID})
			.then((res) => {
				setEvents(res.data)
			})
			.catch(err => console.log(err.response.data.message))
	}, [])

	useEffect(() => {

		if (!_.isNull(id) && !isNaN(id)) {
			dispatch(setLoading(true))

			getAdminDetail(id)
				.then(response => {
					const formClone = _.cloneDeep(form)
					const u = response.data

					formClone.id = u.id
					formClone.username = u.username
					formClone.active = u.active
					formClone.verify = u.verify
					formClone.module = u.module
					formClone.eventAccess = u.eventAccess

					let permissionArr = []
					if(u.permission.includes("C")) {
						permissionArr.push('Create')
					}
					if(u.permission.includes("R")) {
						permissionArr.push('Read')
					}
					if(u.permission.includes("U")) {
						permissionArr.push('Update')
					}
					if(u.permission.includes("D")) {
						permissionArr.push('Delete')
					}
					formClone.permission = permissionArr

					u.Brands.forEach(b => {
						formClone.brandIds.push(b.id)
					})

					setForm(formClone)
				})
				.catch(err => {

				})
				.finally(() => dispatch(setLoading(false)))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	function changeForm(e) {
		const {name, value} = e.target
		const formClone = _.cloneDeep(form)

		formClone[name] = value
		formClone.usernameErr = null
		formClone.passwordErr = null
		formClone.password_confirmationErr = null
		formClone.brandIdsErr = null
		formClone.moduleErr = null

		setForm(formClone)
	}

	function changeBrand(e) {
		const {value} = e.target
		const formClone = _.cloneDeep(form)

		const index = formClone.brandIds.indexOf(parseInt(value))
		if (index === -1) {
			formClone.brandIds.push(parseInt(value))
		} else {
			formClone.brandIds.splice(index, 1)
		}

		setForm(formClone)
	}

	function changeSwitch(e, v) {
		const {name} = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = v
		setForm(formClone)
	}

	function addRemoveModule (e) {
		const {value} = e.target

		const formClone = _.cloneDeep(form)

		const index = formClone.module.indexOf(value)
		if (index === -1) {
			formClone.module.push(value)
		} else {
			formClone.module.splice(index, 1)
		}

		setForm(formClone)
	}

	function addRemovePermission (e) {
		const {value} = e.target

		const formClone = _.cloneDeep(form)

		const index = formClone.permission.indexOf(value)

		if (index === -1) {
			formClone.permission.push(value)
		} else {
			formClone.permission.splice(index, 1)
		}

		setForm(formClone)
	}

	function submit(e) {
		e.preventDefault()
		e.stopPropagation()

		const formClone = _.cloneDeep(form)
		let err = false

		if (_.isEmpty(_.trim(form.username))) {
			formClone.usernameErr = 'Username is required'
			err = true
		}
		if (_.isEmpty(form.brandIds)) {
			formClone.brandIdsErr = 'At least one brand is required'
			err = true
		}
		if (_.isEmpty(form.module)) {
			formClone.moduleErr = 'At least one module is required'
			err = true
		}

		if (_.isNull(form.id)) {
			if (_.isEmpty(_.trim(form.password))) {
				err = true
				formClone.passwordErr = 'Password is required'
			} else if (form.password.length < 8) {
				err = true
				formClone.passwordErr = 'Password is must be at least 8 characters'
			}
			if (_.isEmpty(_.trim(form.password_confirmation))) {
				err = true
				formClone.password_confirmationErr = 'Password confirmation is required'
			}
			if (!_.isEqual(form.password, form.password_confirmation)) {
				err = true
				formClone.password_confirmationErr = 'Password and password confirmation not match'
			}
		} else {
			if (!_.isEmpty(_.trim(form.password)) || !_.isEmpty(_.trim(form.password_confirmation))) {
				if (_.isEmpty(_.trim(form.password))) {
					err = true
					formClone.passwordErr = 'Password is required'
				} else if (form.password.length < 8) {
					err = true
					formClone.passwordErr = 'Password is must be at least 8 characters'
				}
				if (_.isEmpty(_.trim(form.password_confirmation))) {
					err = true
					formClone.password_confirmationErr = 'Password confirmation is required'
				}
				if (!_.isEqual(form.password, form.password_confirmation)) {
					err = true
					formClone.password_confirmationErr = 'Password and password confirmation not match'
				}
			}
		}

		if (err) {
			return setForm(formClone)
		}

		let permissions = ''
		if(formClone.permission) {
			if(formClone.permission.includes("Create")) {
				permissions += 'C'
			}
			if(formClone.permission.includes("Read")) {
				permissions += 'R'
			}
			if(formClone.permission.includes("Update")) {
				permissions += 'U'
			}
			if(formClone.permission.includes("Delete")) {
				permissions += 'D'
			}
		}

		formClone.permission = permissions

		dispatch(setLoading(true))

		let promiseCall = null

		if (_.isNull(form.id)) {
			promiseCall = newAdmin(formClone)
				.then(() => {
					dispatch(setSnackbarMsg('Admin created'))
					navigate('/admin')
				})
		} else {
			promiseCall = editAdmin(form.id, formClone)
				.then(() => dispatch(setSnackbarMsg('Admin information updated')))
		}

		promiseCall
			.catch(err => {
				dispatch(setSnackbarMsg(err.response? err.response.data.message : 'There is error with connection'))
			})
			.finally(() => dispatch(setLoading(false)))

	}

	function confirmDelete() {
		dispatch(setLoading(true))
		deleteAdmin(form)
			.then(() => {
				dispatch(setSnackbarMsg('Admin deleted'))
				navigate('/admin')
			})
			.catch((err) => {
				if (err.response) {
					const {message} = err.response.data
					dispatch(setSnackbarMsg(message))
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	const handleChange = (e) => {
		const { value } = e.target;
		setForm((prev) => ({
			...prev,
			eventAccess: typeof value === 'string' ? value.split(',') : value,
		}));
	};

	return (
		<StyledDiv>
			<PanelHead title={`${id === 'create' ? 'New' : 'Edit'} admin`}>
				<Link to={'/admin'}>
					<Button
						fullWidth={isMobile}
						variant={isMobile? 'outlined' : 'text'}
						color={'inherit'}
						sx={{mr: isMobile? 0 : 2}}>
						Back
					</Button>
				</Link>
				{
					!_.isEmpty(me) && (me.permission.includes("D")) && (
						!_.isNull(form.id) && (
							<Button
							fullWidth={isMobile}
							variant="outlined"
							color={'error'}
							onClick={() => setOnDelete(true)}
							sx={{mt: isMobile? 1 : 0, mr: isMobile ? 0 : 2}}>
							Delete
							</Button>
						)
					)
				}

				{
					!_.isEmpty(me) && (me.permission.includes("U")) && (
						<Button
							fullWidth={isMobile}
							variant="outlined"
							onClick={submit}
							sx={{mt: isMobile? 1 : 0}}>
							Save
						</Button>
					)
				}

			</PanelHead>

			<Box sx={{p: 2}}>

				<Grid container spacing={2}>
					<Grid xs={12} md={6}>
						<Card variant={"outlined"}>
							<CardHeader
								title={'Detail'}
								subheader={'Admin primary detail'}
								subheaderTypographyProps={{variant: 'caption', component: 'div'}} />
							<CardContent>
								{
									!_.isNull(form.id) && (
										<Alert severity={'info'} sx={{mb: 3}}>
											Leave the password empty if you don't want to change the user password
										</Alert>
									)
								}

								<form onSubmit={submit}>
									<button style={{display: 'none'}} type={'submit'} />

									<TextField
										fullWidth
										margin="normal"
										label="Username"
										name="username"
										type="text"
										value={form.username}
										onChange={changeForm}
										error={!_.isNull(form.usernameErr)}
										helperText={form.usernameErr}
										InputLabelProps={{shrink: true}}
										InputProps={{readOnly: !_.isNull(form.id)}}
										autoComplete="username"/>
									<TextField
										fullWidth
										margin="normal"
										label="Password"
										name="password"
										type="password"
										value={form.password}
										onChange={changeForm}
										error={!_.isNull(form.passwordErr)}
										helperText={form.passwordErr}
										InputLabelProps={{shrink: true}}
										autoComplete="password"/>
									<TextField
										fullWidth
										margin="normal"
										label="Password confirmation"
										name="password_confirmation"
										type="password"
										value={form.password_confirmation}
										onChange={changeForm}
										error={!_.isNull(form.password_confirmationErr)}
										helperText={form.password_confirmationErr}
										InputLabelProps={{shrink: true}}
										autoComplete="passwordconfirmation"/>

									{
										!_.isNull(form.id) && (
											<FormGroup component="fieldset" sx={{mt: 3}}>
												<FormControlLabel
													control={<Switch checked={form.active} name={'active'} onChange={changeSwitch}/>}
													label="is active"/>
												<FormControlLabel
													control={<Switch checked={form.verify} name={'verify'} onChange={changeSwitch}/>}
													label="is verified"/>
											</FormGroup>
										)
									}
								</form>
							</CardContent>
						</Card>
					</Grid>
					<Grid xs={12} md={6}>
						<Card variant={"outlined"}>
							<CardHeader
								title={'Brand'}
								subheader={'Assign a specific brand. Must be at least one'}
								subheaderTypographyProps={{variant: 'caption', component: 'div'}} />
							<CardContent>

								{
									!_.isNull(form.brandIdsErr) && (
										<Alert severity={'warning'} sx={{mb: 3}}>{form.brandIdsErr}</Alert>
									)
								}

								<FormGroup component="fieldset">
									{
										brands.map(n => {
											return (
												<FormControlLabel
													key={n.id}
													value={n.id}
													control={<Checkbox checked={form.brandIds.indexOf(n.id) !== -1}/>}
													onChange={changeBrand}
													label={n.name}
												/>
											)
										})
									}
								</FormGroup>
							</CardContent>
						</Card>
					</Grid>
					<Grid xs={12} md={6}>
						<Card variant={"outlined"}>
							<CardHeader
								title={'Module'}
								subheader={'Assign a accessible module'}
								subheaderTypographyProps={{variant: 'caption', component: 'div'}} />
							<CardContent>

								{
									!_.isEmpty(form.moduleErr) && (
										<Alert severity={'warning'} sx={{mb: 3}}>{form.moduleErr}</Alert>
									)
								}

								<FormGroup component="fieldset">
									{
										module.map((m,i) => {
											return (
												<FormControlLabel
													key={i}
													value={m}
													control={<Checkbox checked={form.module.indexOf(m) !== -1} name={'module'} />}
													onChange={addRemoveModule}
													label={_.upperFirst(m)}
												/>
											)
										})
									}
								</FormGroup>
							</CardContent>
						</Card>
					</Grid>
					<Grid xs={12} md={6}>
						<Card variant={"outlined"}>
							<CardHeader
								title={'ACL'}
								subheader={'Global Access Control Level'}
								subheaderTypographyProps={{variant: 'caption', component: 'div'}} />
							<CardContent>
								<FormGroup component="fieldset">
									{
										permission.map((p,i) => {
											return (
												<FormControlLabel
													key={i}
													value={p}
													control={<Checkbox checked={form.permission.indexOf(p) !== -1} disabled={p === 'Read'} name={'permission'} />}
													onChange={addRemovePermission}
													label={p}
												/>
											)
										})
									}
								</FormGroup>
							</CardContent>
						</Card>
					</Grid>
					<Grid xs={12} md={6}>
						<Card variant={"outlined"}>
							<CardHeader
								title={'Eticket event access'}
								subheader={'Give access to eticket events.'}
								subheaderTypographyProps={{variant: 'caption', component: 'div'}} />
							<CardContent>
								{
									!_.isEmpty(events) && (
										<TextField
											select
											fullWidth
											margin="normal"
											label="Eticket Events"
											InputLabelProps={{ shrink: true }}
											name="eventAccess"
											helperText={'Can multi select events'}
											value={form.eventAccess || []}
											onChange={handleChange}
											SelectProps={{
												multiple: true,
											}}>
											<MenuItem value="" disabled>
												Please select
											</MenuItem>
											{
												events.map((event) => (
													<MenuItem key={event.id} value={event.id}>
														{event.title}
													</MenuItem>
												))
											}
										</TextField>
									)
								}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>

			{
				onDelete && <DeleteConfirmationDialog onCancel={() => setOnDelete(false)} onConfirm={confirmDelete}/>
			}

		</StyledDiv>
	)
}
